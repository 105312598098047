import React from "react";
import { graphql, Link } from "gatsby";

import { useStyletron } from "baseui";

import { Grid, Cell } from "baseui/layout-grid";
import { Block } from "baseui/block";
import { Heading, HeadingLevel } from "baseui/heading";
import { Button, KIND } from "baseui/button";
import { ChevronRight } from "baseui/icon";

import Layout from "../components/layout";
import SEO from "../components/seo";
import Artboard from "../components/artboard";
import News from "../components/news";
import NewsCard from "../components/newsCard";
import FrpLogo from "../components/logo-frp";
import MspLogo from "../components/logo-msp";

import { keyRate, keyRateDate } from "../data/keyRate";

import theme from "../themes/cmf";

const IndexPage = ({ data }) => {
  const [css] = useStyletron();

  return (
    <Layout>
      <SEO title={data.site.siteMetadata.company.longCompanyName} />
      <HeadingLevel>
        <Block
          className={css({
            padding: "50px 0",
          })}
        >
          <Artboard date={keyRateDate} rate={keyRate.toLocaleString("ru-RU")} />
        </Block>
        <Block
          className={css({
            backgroundColor: theme.colors.mono200,
            padding: "50px 0",
          })}
        >
          <Grid>
            <Cell span={[4, 6, 8]}>
              <News data={data.allMarkdownRemark} />
              <Button
                $as={Link}
                to="/news"
                endEnhancer={<ChevronRight size={24} />}
                kind={KIND.tertiary}
              >
                Все новости
              </Button>
            </Cell>
            <Cell span={[4, 6, 4]}>
              <HeadingLevel>
                <Heading>Информация</Heading>
                <NewsCard
                  date="Информация о коронавирусе"
                  title="COVID-19"
                  link="/covid"
                />
                <NewsCard
                  date="На 2022 год"
                  title="16 000&nbsp;&#8381;"
                  description="Величина прожиточного минимума в Архангельской области для трудоспособного населения"
                  link="/living-wage"
                />
                <NewsCard
                  title="Оценка и страхование залога"
                  description="Займы на сумму свыше 500&nbsp;000&nbsp;&#8381; должны иметь основное обеспечение в форме залога и (или) банковской гарантии в размере фактически полученной суммы микрозайма и уплаты процентов на него."
                  link="/collateral"
                />
              </HeadingLevel>
            </Cell>
          </Grid>
        </Block>
        <Grid>
          <Cell span={12}>
            <Block
              className={css({
                display: "flex",
                flexWrap: "wrap",
                alignItems: "center",
                justifyContent: "space-around",
              })}
            >
              <Block
                className={css({
                  display: "flex",
                  flexWrap: "nowrap",
                  alignItems: "center",
                  justifyContent: "center",
                  paddingTop: "20px",
                })}
              >
                <FrpLogo />
              </Block>
              <Block
                className={css({
                  display: "flex",
                  flexWrap: "nowrap",
                  alignItems: "center",
                  justifyContent: "center",
                  paddingTop: "20px",
                  paddingRight: "20px",
                })}
              >
                <MspLogo />
              </Block>
            </Block>
          </Cell>
        </Grid>
      </HeadingLevel>
    </Layout>
  );
};

export default IndexPage;

export const query = graphql`
  query IndexPageQuery {
    site {
      siteMetadata {
        title
        company {
          longCompanyName
        }
      }
    }

    allMarkdownRemark(
      limit: 3
      sort: { fields: frontmatter___date, order: DESC }
    ) {
      nodes {
        frontmatter {
          title
          date(formatString: "DD MMMM YYYY", locale: "ru")
          tag
        }
        excerpt
        id
        fields {
          slug
        }
      }
    }
  }
`;
