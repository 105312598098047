import React from "react";
import { Link } from "gatsby";

import { useStyletron } from "baseui";

import { Grid, Cell } from "baseui/layout-grid";
import { Block } from "baseui/block";

import { Heading } from "baseui/heading";
import { Paragraph1 } from "baseui/typography";

import { Button, KIND, SIZE } from "baseui/button";
import { StatefulTooltip } from "baseui/tooltip";

import MainImage from "../images/analysis.svg";

import { SMALL } from "../themes/media-queries";

const Artboard = ({ rate, date }) => {
  const [css, theme] = useStyletron();

  const space = css({ marginLeft: theme.sizing.scale800 });

  const percentRateToolTip = css({
    color: theme.colors.primary,
    borderBottom: `3px dotted ${theme.colors.primary}`,
    cursor: "help",
    ":hover": {
      color: theme.colors.primary500,
    },
    ":focus": {
      outline: `3px solid ${theme.colors.accent}`,
    },
    ":active": {
      outline: `none`,
    },
  });

  return (
    <Grid>
      <Cell span={[4, 6, 6]}>
        <Heading>
          Микрозаймы для малого и среднего бизнеса по ставке не выше{" "}
          <StatefulTooltip
            content={() => (
              <p padding={"20px"}>
                Ключевая ставка Банка&nbsp;России,
                <br />
                действует с {date}
              </p>
            )}
            showArrow
            accessibilityType={"tooltip"}
            focusLock={false}
          >
            <span tabIndex="0" className={percentRateToolTip}>
              {rate}%
            </span>
          </StatefulTooltip>
        </Heading>
        <Paragraph1 margin="0">
          Программы микрокредитования предпринимателей Архангельской области
        </Paragraph1>
        <Block
          className={css({
            marginBottom: theme.sizing.scale800,
            [SMALL]: {
              textAlign: "center",
            },
          })}
        >
          <Button
            className={css({ marginTop: theme.sizing.scale800 })}
            $as={Link}
            to="/apply"
            kind={KIND.primary}
            size={SIZE.large}
          >
            Подать заявку
          </Button>
          <span className={space} />
          <Button
            className={css({ marginTop: theme.sizing.scale400 })}
            $as={Link}
            to="/calculator"
            kind={KIND.tertiary}
            size={SIZE.large}
          >
            Подобрать программу
          </Button>
        </Block>
      </Cell>
      <Cell span={[0, 0, 6]}>
        <MainImage
          className={css({
            maxWidth: "100%",
            height: "auto",
          })}
        />
      </Cell>
    </Grid>
  );
};

export default Artboard;
